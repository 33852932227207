<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
import { useThemeStore } from './stores/theme'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import { onMounted, onUnmounted } from 'vue'
import { useAdminStore } from './stores/admin'

const authStore = useAuthStore()
const themeStore = useThemeStore()
const adminStore = useAdminStore()
let refreshInterval;

onMounted(async () => {
  themeStore.applyTheme()
  await authStore.initialize();
   // Refresh auth state every 5 minutes
  refreshInterval = setInterval(() => {
    authStore.refreshAuth();
  }, 5 * 60 * 1000);

});

onUnmounted(() => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
});

</script>

<template>
  <div class="app-container">
    <nav>
      <div class="nav-left">
        <RouterLink to="/">Home</RouterLink>
        <RouterLink to="/tools">SecurityNews</RouterLink>
        <RouterLink to="/posts">Posts</RouterLink>
      </div>

      <div class="nav-right">
        <ThemeSwitcher />
        <!-- Add debug info display -->
        <span class="debug-info" v-if="authStore.isInitialized">
          User: {{ authStore.user?.login || 'Not logged in' }} |
          Role: {{ authStore.user?.role || 'None' }} |
          Auth: {{ authStore.isAuthenticated }}
        </span>

        <template v-if="authStore.isAuthenticated">
          <RouterLink 
            v-if="authStore.isAdmin"
            to="/admin"
          >
            Admin
          </RouterLink>
          <RouterLink 
            v-else-if="authStore.user?.role === 'moderator'"
            to="/content-management"
          >
            Content Management
          </RouterLink>
          <a href="#" @click.prevent="authStore.logout">Logout</a>
        </template>
        <RouterLink v-else to="/login">Login</RouterLink>
      </div>
    </nav>

    <RouterView />
  </div>
</template>

<style>
.app-container {
  min-height: 100vh;
  background-color: var(--background);
  transition: background-color 0.3s;
}

nav {
  background-color: var(--navBackground);
  padding: 1rem;
  box-shadow: 0 2px 4px var(--shadowColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left, .nav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

nav a {
  color: var(--navText);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
}

nav a:hover {
  background-color: var(--secondaryBackground);
}

nav a.router-link-active {
  color: var(--accentColor);
  background-color: var(--secondaryBackground);
}

@media (max-width: 768px) {
  nav {
    flex-direction: column;
    gap: 1rem;
  }
  
  .nav-left, .nav-right {
    width: 100%;
    justify-content: center;
  }
}

.debug-info {
  background: rgba(0,0,0,0.1);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .debug-info {
    order: -1;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
