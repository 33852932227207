import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  console.error('API Request Error:', error);
  return Promise.reject(error);
});
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Response Error:', error.response || error);

    const customError = {
      message: 'An error occurred',
      status: error.response?.status || 500,
      details: error.response?.data || error.message
    };

    switch (error.response?.status) {
      case 401:
        customError.message = 'Authentication failed. Please log in again.';
        localStorage.removeItem('token');
        window.location.href = '/login';
        break;
      case 403:
        customError.message = 'You do not have permission to perform this action';
        break;
      case 404:
        customError.message = 'Resource not found';
        break;
      case 422:
        customError.message = 'Invalid data provided';
        break;
      case 500:
        customError.message = 'Server error. Please try again later';
        break;
      default:
        customError.message = error.message || 'An unexpected error occurred';
    }

    return Promise.reject(customError);
  }
);
export default api;