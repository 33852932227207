import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import api from '@/utils/api';
import { PERMANENT_ADMIN } from './permissions';

export const useAdminStore = defineStore('admin', () => {
  const loading = ref(false);
  const error = ref(null);
  const admins = ref([]);
  const userRoles = ref([]);

  const adminsList = computed(() => {
    return admins.value.map(admin => ({
      ...admin,
      isSuperAdmin: admin.email === PERMANENT_ADMIN.email
    }));
  });

  async function fetchAllData() {
    loading.value = true;
    error.value = null;
    try {
      const [adminsRes, rolesRes] = await Promise.all([
        api.get('/api/admins'),
        api.get('/api/admins/roles')
      ]);
      admins.value = adminsRes.data.admins;
      userRoles.value = rolesRes.data.roles;
    } catch (err) {
      error.value = err.message;
      throw err;
    } finally {
      loading.value = false;
    }
  }

  async function updateUserRole(email, role) {
    try {
      await api.post('/api/admins', {
        email,
        action: 'update',
        role
      });
      await fetchAllData();
      return true;
    } catch (err) {
      error.value = err.message;
      return false;
    }
  }

  async function removeUserFromRole(email) {
    try {
      await api.post('/api/admins', {
        email,
        action: 'remove'
      });
      await fetchAllData();
      return true;
    } catch (err) {
      error.value = err.message;
      return false;
    }
  }

  return {
    loading,
    error,
    admins,
    userRoles,
    adminsList,
    fetchAllData,
    updateUserRole,
    removeUserFromRole
  };
});
