import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useThemeStore = defineStore('theme', () => {
  const theme = ref(localStorage.getItem('theme') || 'dark')

  const themes = {
    dark: {
      background: '#1a1a1a',
      cardBackground: '#2d2d2d',
      textColor: '#ffffff',
      borderColor: '#404040',
      navBackground: '#000000',
      navText: '#ffffff',
      secondaryBackground: '#333333',
      accentColor: '#3498db',
      headerBackground: '#2d2d2d',
      headerText: '#ffffff',
      headerSubtitle: '#a0a0a0',
      shadowColor: 'rgba(0, 0, 0, 0.3)',
      mutedText: '#a0a0a0'
    },
    grey: {
      background: '#f0f0f0',
      cardBackground: '#ffffff',
      textColor: '#333333',
      borderColor: '#e0e0e0',
      navBackground: '#333333',
      navText: '#ffffff',
      secondaryBackground: '#e5e5e5',
      accentColor: '#3498db',
      headerBackground: '#f0f0f0',
      headerText: '#333333',
      headerSubtitle: '#666666',
      shadowColor: 'rgba(0, 0, 0, 0.1)',
      mutedText: '#666666'
    },
    light: {
      background: '#ffffff',
      cardBackground: '#f8f9fa',
      textColor: '#2c3e50',
      borderColor: '#dee2e6',
      navBackground: '#ffffff',
      navText: '#2c3e50',
      secondaryBackground: '#f8f9fa',
      accentColor: '#3498db',
      headerBackground: '#ffffff',
      headerText: '#2c3e50',
      headerSubtitle: '#6c757d',
      shadowColor: 'rgba(0, 0, 0, 0.05)',
      mutedText: '#6c757d'
    }
  }

  function setTheme(newTheme) {
    theme.value = newTheme
    localStorage.setItem('theme', newTheme)
    applyTheme()
  }

  function applyTheme() {
    const currentTheme = themes[theme.value]
    for (const [key, value] of Object.entries(currentTheme)) {
      document.documentElement.style.setProperty(`--${key}`, value)
    }
  }

  // Apply theme on initialization
  applyTheme()

  return {
    theme,
    setTheme,
    applyTheme,
    themes: Object.keys(themes)
  }
})
