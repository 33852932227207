<template>
  <div class="theme-switcher">
    <button 
      @click="cycleTheme" 
      class="theme-button"
      :title="'Current theme: ' + themeStore.theme"
    >
      <span class="theme-icon">
        {{ themeIcon }}
      </span>
    </button>
  </div>
</template>

<script setup>
import { useThemeStore } from '@/stores/theme'
import { computed } from 'vue'

const themeStore = useThemeStore()

const themeIcon = computed(() => {
  switch (themeStore.theme) {
    case 'dark':
      return '🌑'
    case 'grey':
      return '🌓'
    case 'light':
      return '☀️'
    default:
      return '🌓'
  }
})

function cycleTheme() {
  const themes = ['dark', 'grey', 'light']
  const currentIndex = themes.indexOf(themeStore.theme)
  const nextIndex = (currentIndex + 1) % themes.length
  themeStore.setTheme(themes[nextIndex])
}
</script>

<style scoped>
.theme-switcher {
  display: inline-flex;
  align-items: center;
}

.theme-button {
  background: var(--cardBackground);
  border: 1px solid var(--borderColor);
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: var(--textColor);
}

.theme-button:hover {
  background: var(--secondaryBackground);
  transform: scale(1.05);
}

.theme-icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.theme-button:hover .theme-icon {
  transform: rotate(360deg);
}
</style>
