// Anti-debugging measures
export function setupSecurityMeasures() {
    // Disable right-click
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U
    document.addEventListener('keydown', (e) => {
        if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) ||
            (e.ctrlKey && e.key === 'U')
        ) {
            e.preventDefault();
        }
    });

    // Disable view source
    document.addEventListener('keypress', (e) => {
        if (e.ctrlKey && e.key === 'u') e.preventDefault();
    });

    // Anti-debugging
    const antiDebug = () => {
        function debug() {
            debugger;
        }
        debug();
    };

    // Detect DevTools
    const detectDevTools = () => {
        const threshold = 160;
        const widthThreshold = window.outerWidth - window.innerWidth > threshold;
        const heightThreshold = window.outerHeight - window.innerHeight > threshold;
        
        if (widthThreshold || heightThreshold) {
            document.body.innerHTML = 'Developer tools are not allowed on this site.';
        }
    };

    // Run anti-debugging checks
    setInterval(antiDebug, 100);
    window.addEventListener('resize', detectDevTools);
    detectDevTools();
}

// Source code protection
export function protectSourceCode() {
    // Disable console output in production
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {};
        console.error = () => {};
        console.warn = () => {};
        console.debug = () => {};
        console.info = () => {};
    }

    // Prevent copying text only on non-interactive elements
    document.addEventListener('copy', (e) => {
        const target = e.target;
        if (!(target instanceof HTMLInputElement || 
              target instanceof HTMLTextAreaElement || 
              target.closest('a') || 
              target.closest('button'))) {
            e.preventDefault();
            return false;
        }
    });

    // Prevent dragging content only, not navigation
    document.addEventListener('dragstart', (e) => {
        const target = e.target;
        if (!(target instanceof HTMLAnchorElement || 
              target.closest('a') || 
              target.closest('button') || 
              target.closest('input') || 
              target.closest('router-link'))) {
            e.preventDefault();
            return false;
        }
    });
}
