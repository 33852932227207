import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './style.css'
import { useAuthStore } from './stores/auth.js'
import { setupSecurityMeasures, protectSourceCode } from './utils/security'

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
const authStore = useAuthStore()
authStore.initialize().then(() => {
    app.mount('#app')
}).catch(error => {
    console.error('Failed to initialize auth store:', error)
    // Still mount the app, but in an unauthenticated state
    app.mount('#app')
})

// Setup security measures in production
if (process.env.NODE_ENV === 'production') {
    setupSecurityMeasures();
    protectSourceCode();
}
