import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../stores/auth.js'
import { usePostsStore } from '@/stores/posts'
import HomeView from '@/views/HomeView.vue'
// import { posts } from '../../functions/api/posts'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
      meta: { public: true }
    },
    {
      path: '/auth/callback',
      name: 'auth-callback',
      component: () => import('@/views/AuthCallback.vue'),
      meta: { public: true }
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/AdminView.vue'),
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        const authStore = useAuthStore()

        // Wait for user data to be loaded
        if (!authStore.user) {
          await authStore.validateSession()
        }

        if (authStore.isAdmin) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/post/:id',
      name: 'post-detail',
      component: () => import('@/views/PostView.vue'),
      meta: { public: true }
    },
    {
      path: '/tools',
      name: 'tools',
      component: () => import('@/views/ToolsView.vue'),
      meta: { public: true }
    },
    {
      path: '/content',
      name: 'content',
      component: () => import('@/views/ContentManagement.vue'),
      meta: {
        requiresAuth: true,
        allowedRoles: ['moderator', 'admin', 'superadmin']
      },
      beforeEnter: async (to, from, next) => {
        const authStore = useAuthStore()
        if (!authStore.user) {
          await authStore.validateSession()
        }

        // Include isAdmin check here as well
        if (authStore.isAdmin || ['moderator'].includes(authStore.user?.role?.toLowerCase())) {
          next()
        } else {
          next('/')
        }
      }
    },
  ]
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()
  const postsStore = usePostsStore()
  const userRole = authStore.user?.role || 'Guest'

  // Check if route requires auth
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    next('/login')
    return
  }

  // Check role permissions
  if (to.meta.roles) {
    console.log('Checking role permissions:', {
      userRole,
      requiredRoles: to.meta.roles,
      hasPermission: to.meta.roles.includes(userRole)
    })
    
    if (!to.meta.roles.includes(userRole)) {
      next('/')
      return
    }
  }

  // If navigating to a post route, ensure posts are loaded
  if (to.path.startsWith('/post/')) {
    try {
      await postsStore.fetchPosts()
    } catch (error) {
    }
  }
  
  next()
})

// Add global after hook to handle post-navigation tasks
router.afterEach((to) => {
  // Log navigation for admin routes
  if (to.meta.requiresAdmin) {
  }
})

export default router
