<template>
  <div class="page-container">
    <PageHeader />
    <div class="home">
      <div v-if="postsStore.loading" class="loading">
        Loading posts...
      </div>
      
      <div v-else-if="postsStore.error" class="error">
        {{ postsStore.error }}
        <button @click="retryFetch" class="retry-btn">Retry</button>
      </div>
      
      <div v-else-if="postsStore.posts.length === 0" class="no-posts">
        No posts available yet.
      </div>
      
      <div v-else class="posts-list">
        <router-link 
          v-for="post in postsStore.posts" 
          :key="post.id" 
          :to="`/post/${post.id}`"
          class="post-item"
        >
          <h2>{{ post.title }}</h2>
          <span class="arrow">→</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { usePostsStore } from '@/stores/posts'

const postsStore = usePostsStore()

async function retryFetch() {
  try {
    await postsStore.fetchPosts()
  } catch (error) {
    console.error('Error retrying fetch:', error)
  }
}

onMounted(async () => {
  try {
    await postsStore.fetchPosts()
  } catch (error) {
    console.error('Error in initial fetch:', error)
  }
})
</script>


<style scoped>
.page-container {
  min-height: 100vh;
  background-color: var(--background-color);
}

.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.posts-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;
}

.post-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.post-item h2 {
  margin: 0;
  font-size: 1.2em;
  color: #2c3e50;
}

.arrow {
  color: #3498db;
  font-size: 1.2em;
}

.loading, .error, .no-posts {
  text-align: center;
  padding: 40px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.error {
  color: #dc3545;
}

.retry-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .home {
    padding: 0 15px;
  }

  .post-item h2 {
    font-size: 1.1em;
  }
}
</style>
