import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
  id: 'auth',
  
  state: () => ({
    user: null,
    token: null,
    isAuthenticated: false,
    isInitialized: false
  }),

  getters: {
    isAdmin: (state) => {
      return state.user?.role === 'admin' || state.user?.role === 'superadmin';
    }
  },

  actions: {
    setAuth(authData) {
      this.user = authData.user;
      this.token = authData.token;
      this.isAuthenticated = true;
      this.isInitialized = true;
      
      // Store in localStorage
      localStorage.setItem('auth_token', authData.token);
      localStorage.setItem('auth_user', JSON.stringify(authData.user));
    },

    logout() {
      this.user = null;
      this.token = null;
      this.isAuthenticated = false;
      
      // Clear localStorage
      localStorage.removeItem('auth_token');
      localStorage.removeItem('auth_user');
    },

    async initialize() {
      const token = localStorage.getItem('auth_token');
      const storedUser = localStorage.getItem('auth_user');

      if (!token) {
        this.logout();
        this.isInitialized = true;
        return;
      }

      try {
        // Set initial state from localStorage
        if (storedUser) {
          this.user = JSON.parse(storedUser);
          this.token = token;
          this.isAuthenticated = true;
        }

        // Fetch fresh user data
        const response = await fetch('/api/users/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const { user } = await response.json();
        
        this.setAuth({ 
          user,
          token
        });
      } catch (error) {
        console.error('Failed to initialize auth:', error);
        this.logout();
      } finally {
        this.isInitialized = true;
      }
    }
  }
});

/*import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
  id: 'auth',
  
  state: () => ({
    user: JSON.parse(localStorage.getItem('auth_user')),
    token: localStorage.getItem('auth_token'),
    isAuthenticated: !!localStorage.getItem('auth_token')
  }),

  getters: {
    isAdmin: (state) => {
      return state.user?.role === 'admin' || state.user?.role === 'superadmin';
    }
  },

  actions: {
    setAuth(authData) {
      this.user = authData.user;
      this.token = authData.token;
      this.isAuthenticated = true;
      
      // Store in localStorage
      localStorage.setItem('auth_token', authData.token);
      localStorage.setItem('auth_user', JSON.stringify(authData.user));
    },

    logout() {
      this.user = null;
      this.token = null;
      this.isAuthenticated = false;
      
      // Clear localStorage
      localStorage.removeItem('auth_token');
      localStorage.removeItem('auth_user');
    },

    async initialize() {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        this.logout();
        return;
      }

      try {
        const response = await fetch('/api/users/me', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const data = await response.json();
        
        // Update auth with potentially refreshed token
        this.setAuth({ 
          user: data.user,
          token: data.token || token // Use new token if provided
        });
      } catch (error) {
        console.error('Failed to initialize auth:', error);
        this.logout();
      }
    },

    // Add method to refresh auth state
    async refreshAuth() {
      if (!this.token) {
        this.logout();
        return;
      }

      try {
        await this.initialize();
      } catch (error) {
        console.error('Failed to refresh auth:', error);
        this.logout();
      }
    }
  }
});*/