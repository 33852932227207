import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAuthStore } from './auth'

export const usePostsStore = defineStore('posts', () => {
  const posts = ref([])
  const loading = ref(false)
  const error = ref(null)
  const authStore = useAuthStore()

  async function fetchPosts() {
    loading.value = true
    error.value = null
    try {
      const response = await fetch('/api/posts')
      if (!response.ok) throw new Error('Failed to fetch posts')
      posts.value = await response.json()
    } catch (err) {
      console.error('Error fetching posts:', err)
      error.value = err.message
    } finally {
      loading.value = false
    }
  }

  async function createPost(postData) {
    loading.value = true
    error.value = null
    try {
      const response = await fetch('/api/posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authStore.token}`
        },
        body: JSON.stringify(postData)
      })
      
      if (!response.ok) {
        throw new Error('Failed to create post')
      }
      
      return await response.json()
    } catch (err) {
      console.error('Error creating post:', err)
      error.value = err.message
      throw err
    } finally {
      loading.value = false
    }
  }

  async function updatePost(postId, postData) {
    loading.value = true;
    error.value = null;
    
    try {
      console.log(`Sending PUT request to /api/posts/${postId}/update`);
      const response = await fetch(`/api/posts/${postId}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...postData,
          updatedAt: new Date().toISOString()
        })
      });

      console.log('Update response status:', response.status);
      const data = await response.json();
      console.log('Update response data:', data);

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Failed to update post');
      }

      // Update local posts array
      const index = posts.value.findIndex(p => p.id === postId);
      if (index !== -1) {
        posts.value[index] = data.post;
      }
      
      return true;
    } catch (err) {
      console.error('Error updating post:', err);
      error.value = err.message;
      return false;
    } finally {
      loading.value = false;
    }
  }

  async function deletePost(postId) {
    loading.value = true;
    error.value = null;
    
    try {
      console.log(`Sending DELETE request to /api/posts/${postId}/delete`);
      const response = await fetch(`/api/posts/${postId}/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log('Delete response status:', response.status);
      const data = await response.json();
      console.log('Delete response data:', data);

      if (!response.ok || !data.success) {
        throw new Error(data.error || 'Failed to delete post');
      }

      // Remove from local posts array
      posts.value = posts.value.filter(post => post.id !== postId);
      return true;
    } catch (err) {
      console.error('Error deleting post:', err);
      error.value = err.message;
      return false;
    } finally {
      loading.value = false;
    }
  }

  async function fetchPost(id) {
    try {
      const response = await fetch(`/api/posts/${id}`)
      if (!response.ok) throw new Error('Failed to fetch post')
      const post = await response.json()
      return post
    } catch (err) {
      console.error('Error fetching post:', err)
      throw err
    }
  }

  return {
    posts,
    loading,
    error,
    fetchPosts,
    createPost,
    updatePost,
    deletePost,
    fetchPost
  }
})
