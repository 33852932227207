<template>
  <header class="page-header">
    <div class="header-content">
      <h1>LinuxRepair <span class="subtitle">The House of Security</span></h1>
    </div>
  </header>
</template>

<script setup>
import { useThemeStore } from '@/stores/theme'
import { computed } from 'vue'

const themeStore = useThemeStore()

const headerClass = computed(() => `theme-${themeStore.theme}`)
</script>

<style scoped>
.page-header {
  padding: 20px 0;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px var(--shadowColor);
  width: 100%;
  transition: all 0.3s ease;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* Theme-specific styles */
.page-header {
  background-color: var(--headerBackground);
}

h1 {
  font-size: 2.2em;
  margin: 0;
  font-weight: 600;
  color: var(--headerText);
}

.subtitle {
  font-size: 0.5em;
  display: block;
  margin-top: 5px;
  font-weight: 400;
  color: var(--headerSubtitle);
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8em;
  }
}
</style>
