// File: src/stores/admin/permissions.js

export const ALLOWED_DOMAINS = [
  'https://linux.repair',
  'https://linuxrepair.pages.dev'
];

export const PERMANENT_ADMIN = {
  email: 'admin@hostme.co.il',
  name: 'InselfControll',
  role: 'superadmin',
  dateAdded: '2024-10-27'
}

// Role hierarchy - higher number means higher privileges
export const ROLE_HIERARCHY = {
  'superadmin': 4,
  'admin': 3,
  'moderator': 2,
  'user': 1
}

export const ROLE_TITLES = {
  superadmin: 'Super Admin',
  admin: 'Administrator',
  moderator: 'Moderator',
  user: 'Regular User'
}

// Detailed permissions for each role
export const ROLE_PERMISSIONS = {
  superadmin: {
    permissions: '*',
    canManagePosts: true,
    canManageAdmins: true,
    canManageSettings: true,
    canDeletePosts: true,
    canBanUsers: true,
    canManagePermissions: true,
    canApproveContent: true,
    canAccessAdminPanel: true,
    canEditComments: true,
    canModerateComments: true
  },
  admin: {
    permissions: 'specific',
    canManagePosts: true,
    canManageAdmins: false,
    canManageSettings: false,
    canDeletePosts: true,
    canBanUsers: true,
    canManagePermissions: false,
    canApproveContent: true,
    canAccessAdminPanel: true,
    canEditComments: true,
    canModerateComments: true
  },
  moderator: {
    permissions: 'specific',
    canEditRoles: false,
    canAddAdmins: false,
    canEditAllPosts: true,
    canDeletePosts: false,
    canManageUsers: false,
    canDeleteHigherRoleComments: false,
    canAccessAdminPanel: true,
    canEditComments: true,
    canModerateComments: true
  },
  user: {
    permissions: 'basic',
    canCreatePosts: true,
    requiresApproval: true,
    canAccessAdminPanel: false,
    canEditComments: false,
    canModerateComments: false
  }
}

// Helper functions
export function hasPermission(userRole, permission) {
  if (!userRole) return false
  const role = userRole.toLowerCase()
  return ROLE_PERMISSIONS[role]?.[permission] || false
}


export function isPermanentAdmin(user) {
  return user?.email === PERMANENT_ADMIN.email
}

export function getUserPermissions(user) {
  if (!user || !user.role) return ROLE_PERMISSIONS.user // Default to basic user permissions
  if (isPermanentAdmin(user)) return ROLE_PERMISSIONS.superadmin
  const role = user.role.toLowerCase()
  return ROLE_PERMISSIONS[role] || ROLE_PERMISSIONS.user
}


export function getRoleTitle(role) {
  return ROLE_TITLES[role?.toLowerCase()] || ROLE_TITLES.user
}

export function canModerateContent(userRole, targetRole) {
  if (!userRole || !targetRole) return false;
  const userHierarchy = ROLE_HIERARCHY[userRole.toLowerCase()];
  const targetHierarchy = ROLE_HIERARCHY[targetRole.toLowerCase()];
  return userHierarchy > targetHierarchy;
}
